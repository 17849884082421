import React, {createRef, RefObject, useEffect, useRef, useState} from "react";
import { Carousel } from "antd";
import {CarouselRef} from "antd/es/carousel";
import Left from "../assets/icons/left-fill.svg";
import Right from "../assets/icons/right-fill.svg";
import gsap from "gsap";
import Dot from "../assets/icons/roadmap-dot.svg";


const RoadmapSlider = () => {
    const [current, setCurrent] = useState(0);
    const carouselRef: RefObject<CarouselRef> = createRef<CarouselRef>();

    useEffect(() => {
        gsap.set(".line-slider", {x: 124 , y: 105, width: 200, height: 1, background: "#B4001E"});
        gsap.set(".dot", {x: 321 , y: 100, width: 12,});
    },[]);

    const onChangeNext = () => {
        if (current < data.length - 1) {
            setCurrent(current + 1)
            carouselRef.current?.next();

            switch (current) {
                case 0:
                    gsap.to(".line-slider", {
                        duration: .8,
                        width: 416,
                        height: 1,
                        ease: "power1.inOut",
                    });
                    gsap.to(".dot", {
                        duration: .8,
                        x: 537,
                        ease: "power1.inOut",
                    });
                    break;
                case 1:
                    gsap.to(".line-slider", {
                        duration: .8,
                        width: 956,
                        height: 1,
                        ease: "power1.inOut",
                    });
                    gsap.to(".dot", {
                        duration: .8,
                        x: 1075,
                        ease: "power1.inOut",
                    });
            }


        }
    };
    const onChangePrev = () => {
        if (current > 0) {
            setCurrent(current - 1);
            carouselRef.current?.prev();

            switch (current) {
                case 2:
                    gsap.to(".line-slider", {
                        duration: .8,
                        width: 416,
                        height: 1,
                        ease: "power1.inOut",
                    });
                    gsap.to(".dot", {
                        duration: .8,
                        x: 537,
                        ease: "power1.inOut",
                    })
                    break;
                case 1:
                    gsap.to(".line-slider", {
                        duration: .8,
                        width: 200,
                        height: 1,
                        ease: "power1.inOut",
                    });
                    gsap.to(".dot", {
                        duration: .8,
                        x: 321,
                        ease: "power1.inOut",
                    })
                    break;
                default: return
            }

        }
    };

    const data = [
        {
            title: "December",
            content1: "RED COST is the official marketplace for valuing, buying and selling digital assets (NFT). C" +
                "omplies with all current cryptequirements. All transactions are legal and transparent." +
                "To create a safe and cnts. All transactions are legal and transparent.' +\n" +
                "                \"To create a safe and convenient platform for everyform for everyone who understands the topic and wants to earn safely on NFT.",
            content2: 'RED COST is the official marketplace for valuing, buying and selling digital assets (NFT).' +
                'Complies with all current All transactions are legal and transparent.\' +\n' +
                '                "To create a safe and convenient platform for everyency security requirements. All transactions are legal and transparent.' +
                "To create a safe and convenient platform for."
        },
        {
            title: "January",
            content1: "RED COST is the official marketplace for valuing, buying and selling digital assets (NFT). C" +
                "ofor everyoons are legal and transparent." +
                "To create a safe and convenient platform for everyone who understands the topic and wants to earn safely on NFT.",
            content2: 'RED COST is the official marketplace for valuing, buying and selling digital assets (NFT).' +
                'Complies with all cu transparent.' +
                "To create a safe and conv and convenient pla enient platform for everyone who understands the topic and wants to earn safely on NFT."
        },
        {
            title: "February",
            content1: "RED COST is the official marketplace for valuing, buying and selling digital assets (NFT). C" +
                "omplies withcurity requirements. All transactions are legal and transparent." +
                "To create a safe and convenient platform for everyone who understands the topic and wants to earn safely on NFT.",
            content2: 'RED COST is the official marketplace for valuing, buying and selling digital assets (NFT).' +
                'Complies with all cu transparent.' +
                "To create a safplies with all cu transparent. create a safe and convenient platform for everyone who understands the topic and wants to earn safely on NFT."
        },

    ]

    return (
        <div className="antd-roadmap">
            <div className="line-slider"></div>
            <img src={Dot} alt="" className="dot"/>

            <div onClick={onChangePrev} className="left-button-vertical">
                <img src={Left} alt=""/>
            </div>
            <div onClick={onChangeNext} className="right-button-vertical">
                <img src={Right} alt=""/>
            </div>
            <Carousel
                dots={false}
                ref={carouselRef}
                effect="fade"
                infinite={false}
                speed={700}
                rootClassName="about-roadmap-carousel"
            >
                {data.map((data, index) => {
                    return (
                        <div className="slider-body" key={index}>
                            <div className="title1">{data.title} <span className="year">/2023</span></div>
                            <div className="slider-content">
                                <div className="sub-content">{data.content1}</div>
                                <div className="sub-content">{data.content2}</div>
                            </div>
                        </div>
                    )
                })}
            </Carousel>
        </div>
    )
};

export default RoadmapSlider;
