import user from "../assets/icons/user.svg";
import mageEye from "../assets/icons/mage_eye.svg";
import nounDeal from "../assets/icons/noun-deal.svg";
import studio from "../assets/icons/studio.svg";
import settings from "../assets/icons/settings.svg";
import logout from "../assets/icons/logout.svg";


export const navItems = [
    "About",
    "Discover",
    "AirDrop",
    "For investors",
    "Contact"
]

export const profileItem = [
    {
        icon: user,
        option: "Profile"
    },
    {
        icon: mageEye,
        option: "Watchlist"
    },
    {
        icon: nounDeal,
        option: "Deals"
    },
    {
        icon: studio,
        option: "Studio"
    },
    {
        icon: settings,
        option: "Settings"
    },
    {
        icon: logout,
        option: "Logout"
    },

]