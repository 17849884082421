import React, {useState} from "react";
import Logo from "../assets/logo.svg";
import {socialItems} from "../constants/socialItems";
import rightFill from "../assets/icons/right-fill.svg"
import mobileLogo from "../assets/mobile-logo.svg"
import close from "../assets/icons/close.svg";
import {useNavigate} from "react-router-dom";
import {Dropdown, MenuProps, Space} from "antd";
import ArrowUpGrey from "../assets/icons/arrow-up-grey.svg";


function Footer () {
    const [isOpen, setIsOpen] = useState(false);
    const [activeIndex, setActiveIndex] = useState(null);
    const [nftDropdownOpen, setNftDropdownOpen] = useState<any>();



    const navigate = useNavigate()

    const handleMouseMove = (e:any) => {
        const boundingRect = e.currentTarget.getBoundingClientRect();
        const mouseX = e.clientX - boundingRect.left;
        const mouseY = e.clientY - boundingRect.top;
        const centerX = boundingRect.width / 8;
        const centerY = boundingRect.height / 2;
        const maxRotateX = 15; // Maximum rotation angle around X-axis
        const maxRotateY = 1; // Maximum rotation angle around Y-axis
        const perspective = 4000; // Perspective value

        const rotateX = ((mouseY - centerY) / centerY) * maxRotateX;
        const rotateY = ((mouseX - centerX) / centerX) * maxRotateY;

        e.currentTarget.style.transform = `perspective(${perspective}px) rotateX(${rotateX}deg) rotateY(${rotateY}deg)`;
    };

    const handleMouseLeave = (e:any) => {
        e.currentTarget.style.transform = 'perspective(300px) rotateX(0deg) rotateY(0deg)';
    };
    const openSocialGroup = () => {
        setIsOpen(!isOpen);
    };
    const handleClickSocialItem = (item:String) => {
        switch (item) {
            case "x":
                window.open("https://x.com/", '_blank');
                break;
            case "discord":
                window.open("https://discord.com/", '_blank');
                break;
            case "facebook":
                window.open("https://facebook.com/", '_blank');
                break;
            case "youtube":
                window.open("https://youtube.com/", '_blank');
                break;
            case "linkedin":
                window.open("https://linkedin.com/", '_blank');
                break;
            case "reddit":
                window.open("https://reddit.com/", '_blank');
                break;
            case "medium":
                window.open("https://medium.com/", '_blank');
                break;
            case "instagram":
                window.open("https://instagram.com/", '_blank');
                break;
            case "telegram":
                window.open("https://telegram.org/", '_blank');
                break;
            case "threads":
                window.open("https://www.threads.net/", '_blank');
                break;
            default:
                console.log(`Click on ${item} and navigate ${item}`)
        }

    }

    const handleClickNavItem = (item: any) => {

        switch (item) {
            case "About":
                navigate("./about")
                break;
            case "Discover":
                navigate("./discover")
                break;
            case "AirDrop":
                navigate("./airdrop")
                break;
            case "For investors":
                navigate("./investors")
                break;
            case "Contact":
                navigate("./contacts")
                break;

            default:
                navigate("./error")
        }
    }
    const handleUsersMarket = () => {
        setActiveIndex(null)
        navigate("./market")
    };
    const handleRedCostMarket = () => {
        setActiveIndex(null)
        navigate("./collection")
    };
    const handleOpenNftDropdown = (e:any) => {
        setNftDropdownOpen(e)
    };
    const items: MenuProps['items'] = [
        {
            key: '1',
            label: (
                <a className="market-nft-dropdown-items" rel="noopener noreferrer" onClick={handleUsersMarket}>
                    Auction Market
                </a>
            ),
        },
        {
            key: '2',
            label: (
                <a className="market-nft-dropdown-items" rel="noopener noreferrer" onClick={handleRedCostMarket}>
                    RedCost Collection
                </a>
            ),
        },
    ];


    return (
        <div className="footer">
            <div className="footer-top">
                <div className="footer-top-left">
                    <div className="logo">
                        <img src={Logo} alt="logo" className="footer-logo-web"
                             onMouseMove={handleMouseMove}
                             onMouseLeave={handleMouseLeave}
                        />
                        <img src={mobileLogo} alt="logo" className="footer-logo-mob"/>
                    </div>
                    <div className="footer-top-left-text">
                        new era for making money on NFT assets
                    </div>
                </div>
                <div className="footer-top-right">
                    <div className="nav-footer">
                        <div className="nav-footer-item" onClick={() => handleClickNavItem("About")}>About</div>
                        <div className="nav-footer-item" onClick={() => handleClickNavItem("For investors")}>For investors</div>
                        <div className="nav-footer-item" onClick={() => handleClickNavItem("Discover")}>Discover</div>
                        <div className="nav-footer-item" onClick={() => handleClickNavItem("Contact")}>Contact</div>
                        <div className="nav-footer-item" onClick={() => handleClickNavItem("AirDrop")}>AirDrop</div>
                        <div className="nav-footer-item-market">
                            <Dropdown
                                menu={{ items }}
                                trigger={["click"]}
                                overlayClassName="nft-dropdown"
                                onOpenChange={handleOpenNftDropdown}
                                placement="bottomRight"
                            >
                                <a onClick={(e) => e.preventDefault()} >
                                    <Space>
                                        <span className="market-text">Market NFT</span>
                                        <img src={ArrowUpGrey} alt="up" className={nftDropdownOpen ? "rotate-arrow-transition": "rotate-arrow-transition rotate-arrow"}/>
                                    </Space>
                                </a>
                            </Dropdown>
                        </div>
                    </div>
                </div>
            </div>

            <div className="footer-bottom">
                <div className="footer-bottom-left footer-bottom-left-mobile">
                    <div className="footer-bottom-left-item">© 2024</div>
                    <div className="footer-bottom-left-item">WhitePaper</div>
                    <div className="footer-bottom-left-item">Privacy Policy</div>
                </div>
                <div className="footer-bottom-right-group">
                    <div className={!isOpen? "footer-bottom-right": "footer-bottom-right open-icons-group"}>
                        {socialItems.map((socialItem:any,index) =>{
                            return <div
                                key={index}
                                onClick={() => handleClickSocialItem(socialItem.name)}
                            >
                                <div className="svg-container">
                                    <img src={socialItem.logo} alt="" className="image1"/>
                                    <img src={socialItem.logored} alt="" className="image2"/>
                                </div>
                            </div>
                        })}
                    </div>
                    {!isOpen?
                        <img src={rightFill} alt="rightFill" className="social-group-logo-img" onClick={openSocialGroup}/>:
                        <img src={close} alt="leftFill" className="social-group-logo-img" onClick={openSocialGroup}/>
                    }
                </div>

                {/*Mobile social Items Scroll*/}

                <div className="footer-bottom-right-group-mob">
                    {socialItems.map((socialItem:any,index) =>{
                        return <div
                            key={index}
                            onClick={() => handleClickSocialItem(socialItem.name)}
                        >
                            <div className="svg-container">
                                <img src={socialItem.logo} alt="" className="image1"/>
                                <img src={socialItem.logored} alt="" className="image2"/>
                            </div>
                        </div>
                    })}
                </div>
            </div>
        </div>
    )
}

export default Footer;
