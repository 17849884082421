import React, {useEffect, useState} from "react";
import CollectionStar from "../assets/collection-star.svg";
import Views from "../assets/views.svg";
import Favorites from "../assets/favorites.svg";
import FavoritesRed from "../assets/favorite-red.svg";
import Category from "../assets/category.svg";
import Basket from "../assets/icons/basket.svg";
import Description from "../assets/icons/description.svg"
import {Dropdown, Modal, Space} from "antd";
import type {MenuProps} from 'antd';
import DetailsIcon from "../assets/icons/details-icon.svg";
import ArrowUp from "../assets/icons/arrow-up-grey.svg";
import PriceHistory from "../assets/icons/price-history.svg";
import ChartLine from "../assets/icons/chart-hover-line.svg";
import { buyNFT, getAuctionData} from "../web3";
import {useWeb3ModalAccount} from '@web3modal/ethers/react'
import Loading from "../auth/Loading";

function NFTDetailsCollection() {
    const [views, setViews] = useState<any>("000");
    const [favorites, setFavorites] = useState<any>("000");
    const [priceBNB, setPriceBNB] = useState<number>(1000);
    const [priceUSD, setPriceUSD] = useState<number>(5000);
    const [openDropdown, setOpenDropdown] = useState<any>();
    const [nftItem, setNftItem] = useState<any>();
    const {address, chainId, isConnected} = useWeb3ModalAccount();
    const [loading, setLoading] = useState<boolean>(false);
    const [transactionModalOpen, setTransactionModalOpen] = useState(false);
    const [transactionStatus, setTransactionStatus] = useState(false);



    useEffect(() => {
        let cat:any = localStorage.getItem("myNFT");
        if (cat) {
            var auctionInfo = JSON.parse(cat)
            setNftItem(auctionInfo)
            getAuctionData(auctionInfo.tokenIndex).then(res => {
                setPriceBNB(res?.currentPrice / 10 ** 18)
            })
        }

    }, []);


    const handleBuyNFT = () => {
        setLoading(true)
        buyNFT(nftItem?.token_index)
        setLoading(false)

    }

    const onOpenChange = (e: any) => {
        setOpenDropdown(e)
    }

    const items: MenuProps['items'] = [
        {
            key: '1',
            label: <div className="dropdown-items">
                <div className="dropdown-items_left">Contract Address</div>
                <div className="dropdown-items_right">xxx</div>
            </div>
        },
        {
            key: '2',
            label: <div className="dropdown-items">
                <div className="dropdown-items_left">Token ID</div>
                <div className="dropdown-items_right">xxx</div>
            </div>
        },
        {
            key: '3',
            label: <div className="dropdown-items">
                <div className="dropdown-items_left">Token Standard</div>
                <div className="dropdown-items_right">xxx</div>
            </div>
        },
        {
            key: '4',
            label: <div className="dropdown-items">
                <div className="dropdown-items_left">Chain</div>
                <div className="dropdown-items_right">xxx</div>
            </div>
        },
        {
            key: '5',
            label: <div className="dropdown-items">
                <div className="dropdown-items_left">Last Updated</div>
                <div className="dropdown-items_right">xxx</div>
            </div>
        },
    ];

    return (
        <>
            {
                loading ? <Loading/> :
                    <div className="nft-details-main">
                        <div className="nft-details-first">
                            <div className="nft-details-first_left">
                                <div className="nft-details-mobile-header">
                                    <div className="nft-details-mobile-header_collection-name">
                                        <img src={CollectionStar} alt="CollectionStar"/>
                                        <span>collection_name</span>
                                    </div>
                                    <div className="nft-details-mobile-header_owner-name">
                                        <div className="nft-details-mobile-header_owner-name__name">JJJJJJJ</div>
                                        <div className="nft-details-mobile-header_owner-name__owner">Owned
                                            by <span>@author_name</span></div>
                                    </div>
                                </div>
                                <img src={nftItem?.tokenURI} alt=""/>
                            </div>
                            <div className="nft-details-first_right">
                                <div className="nft-details-first_right__name">NAME</div>
                                <div className="nft-details-first_right__collection-details">
                                    <div className="nft-details-first_right__collection-details___owner">Owned
                                        by <span>@author_name</span></div>
                                    <div className="nft-details-first_right__collection-details___collection-name">
                                        <img src={CollectionStar} alt="CollectionStar"/>
                                        <span>collection_name</span>
                                    </div>
                                </div>
                                <div className="rating mt-30">
                                    <div className="rating_items">
                                        <img src={Views} alt=""/>
                                        <span>{views} views</span>
                                    </div>
                                    <div className="rating_items">
                                        <img src={FavoritesRed} alt=""/>
                                        <span>{favorites} favorites</span>
                                    </div>
                                    <div className="rating_items">
                                        <img src={Category} alt=""/>
                                        <span>category</span>
                                    </div>
                                </div>
                                <div className="collection-actions actions-position">
                                    <div className="collection-actions_title">Current price</div>
                                    <div className="collection-actions_current-price">
                                        <div
                                            className="collection-actions_current-price_bnb">{priceBNB.toLocaleString()} BNB
                                        </div>
                                        <div
                                            className="collection-actions_current-price_usd">${priceUSD.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}</div>
                                    </div>
                                    <div className="nft-buy-buttons-gropup">
                                        <div className="nft-buy-button button-position" onClick={handleBuyNFT}>
                                            <img src={Basket} alt=""/>
                                            <span>Buy</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="nft-details-second">
                            <div className="nft-details-second_left">
                                <div className="nft-details-second_left_header">
                                    <img src={Description} alt=""/>
                                    <span>Description</span>
                                </div>
                                <div className="nft-details-second_left_description">
                                    <div className="scroll-div">
                            <span>RED COST is the official marketplace for valuing, buying and selling digital assets (NFT). Complies with all current cryptocurrency security requirements. All transactions are legal and transparent.
                                <br/>
                                <br/>
                                To create a safe and convenient platform for everyone who
                            </span>
                                    </div>
                                </div>
                                <Dropdown
                                    menu={{items}}
                                    trigger={["click"]}
                                    onOpenChange={onOpenChange}
                                    className="details-dropdown"
                                >
                                    <span onClick={(e) => e.preventDefault()}>
                                        <Space>
                                            <div className="dropdown-left">
                                                <img src={DetailsIcon} alt=""/>
                                                <span>Details</span>
                                            </div>
                                            {/*{openDropdown ? <img src={ArrowUp} alt=""/> : <img src={ArrowDown} alt=""/>}*/}
                                            <img src={ArrowUp} alt="up"
                                                 className={openDropdown ? "rotate-arrow-transition" : "rotate-arrow-transition rotate-arrow"}/>
                                        </Space>
                                    </span>
                                </Dropdown>
                            </div>
                            <div className="nft-details-second_right">
                                <div className="nft-details-second_right_header">
                                    <img src={PriceHistory} alt=""/>
                                    <span>Price History</span>
                                </div>
                                <div className="nft-details-second_right_description">
                                    <div className="chart-content">
                                        <div className="left-vertical">Volume (ETH)</div>
                                        {[1, 2, 3, 4].map((item) => <div className="chart-content_item" key={item}>
                                            <div className="chart-content_price">0.0</div>
                                            <div className="chart-content_line"></div>
                                            <div className="chart-content_price">0.0</div>
                                        </div>)}
                                        <div className="right-vertical">Average price<br/>(ETH)</div>

                                        <div className="chart">
                                            <img src={ChartLine} alt="" className="line-image"/>
                                            <div className="chart-info">
                                                <div className="chart-info_header">0.00 ETH</div>
                                                <div className="chart-info_content">
                                                    Avg. price: 0,000 ETH
                                                    <br/>
                                                    Num. sales: 0
                                                    <br/>
                                                    May 27 at 0:00 AM
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Modal
                            centered
                            width={609}
                            open={transactionModalOpen}
                            onCancel={() => setTransactionModalOpen(false)}
                            footer={null}
                            className="approve-popup"
                        >
                            {transactionStatus ?
                                <div className="transaction-status">
                                    <div className="transaction-status_title">Thank you!</div>
                                    <div className="transaction-status_info">Your transaction has been successfully completed.</div>
                                </div>:
                                <div className="transaction-status">
                                    <div className="transaction-status_title">Transaction Error</div>
                                    <div className="transaction-status_info">Sorry, your transaction failed.</div>
                                </div>}

                        </Modal>
                    </div>
            }
        </>)
}

export default NFTDetailsCollection;