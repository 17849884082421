import React, {useEffect, useRef, useState} from "react";
import gsap from "gsap";
import { Flip } from "gsap/Flip";
import RoadmapIcon from "../assets/icons/roadmap-icon.svg";
import Dot from "../assets/icons/roadmap-dot.svg";
import RoadmapNext from "../assets/icons/roadmap-next.svg";
import RoadmapPrev from "../assets/icons/prev-line.svg";

gsap.registerPlugin(Flip);

function RoadmapGsap  ()  {
    const gridRef:any = useRef(null);
    const itemsRef:any = useRef([]);

    useEffect(() => {
        const grid:any = gridRef.current;
        // const items:any = gsap.utils.toArray(".rg_item");
        const items:any = [".rg_item1",".rg_item2",".rg_item3",".rg_item4",".rg_item5"];
        const timeline = gsap.timeline();

        gsap.set(".rg_item1", {x: 235 , y: 20, fontSize: 36, paddingTop: 59, paddingLeft: 30, background: "#FFFFFF"});
        gsap.set(".rg_item2", {x: 280 , y: 20, fontSize: 20, paddingTop: 36, paddingLeft: 30, background: "#FFFFFF"});
        gsap.set(".rg_item3", {x: 12 , y: 195, fontSize: 20, paddingTop: 36, paddingLeft: 30, background: "#FFFFFF"});
        gsap.set(".rg_item4", {x: 50 , y: 0, fontSize: 20, paddingTop: 36, paddingLeft: 30, background: "#FFFFFF"});
        gsap.set(".rg_item5", {x: -240 , y: 155, fontSize: 20, paddingTop: 36, paddingLeft: 30, background: "#FFFFFF"});

        gsap.set(".l_1", {x: -1240, y: 38, opacity: 0});
        gsap.set(".dot_1", {y: 33, opacity: 0});

        gsap.set(".l_2", {x: -740, y: 38, opacity: 0});
        gsap.set(".l_3", {x: 144, y: 140, opacity: 0});
        gsap.set(".dot_2", {x: 24, y: 56, opacity: 0});

        gsap.set(".l_4", {x: 144, y: 240, opacity: 0});
        gsap.set(".dot_3", {x: 24, y: 218, opacity: 0});

        gsap.set(".l_5", {x: 250, y: 305, opacity: 0});
        gsap.set(".l_6", {x: 470, y: 235, opacity: 0});
        gsap.set(".dot_4", {x: 350, y: 187, opacity: 0});

        gsap.set(".l_7", {x: 470, y: 75, opacity: 0});
        // gsap.set(".l_8", {x: -750, y: 8, opacity: 0}); //if image animate
        gsap.set(".l_8", {x: 543, y: 8, opacity: 1});
        gsap.set(".l_9", {x: 482, y: 8.5, opacity: 0});
        gsap.set(".dot_5", {x: 350, y: 32, opacity: 0});



        let count = 0

        function handleShuffleNext () {

            // const l1:any = document.querySelector(".l_1");
            // const l2:any = document.querySelector(".l_2");
            // const l3:any = document.querySelector(".l_3");
            // const l4:any = document.querySelector(".l_4");
            // const l5:any = document.querySelector(".l_5");
            // const l6:any = document.querySelector(".l_6");
            // const l7:any = document.querySelector(".l_7");

            buttonNext.disabled = true;
            // l1.style.opacity = 0;
            // l2.style.opacity = 0;
            // l3.style.opacity = 0;
            // l4.style.opacity = 0;
            // l5.style.opacity = 0;
            // l6.style.opacity = 0;
            // l7.style.opacity = 0;
            setTimeout(function(){
                buttonNext.disabled = false;
            }, 2000);

            // setTimeout(function(){
            //     if (count === 0) {
            //         l1.style.opacity = 0;
            //     }
            //     else if (count === 1){
            //         l1.style.opacity = 1;
            //     }
            //     else if (count === 2) {
            //         l1.style.opacity = 1;
            //         l2.style.opacity = 1;
            //         l3.style.opacity = 1;
            //     }
            //     else if (count === 3) {
            //         l1.style.opacity = 1;
            //         l2.style.opacity = 1;
            //         l3.style.opacity = 1;
            //         l4.style.opacity = 1;
            //     }
            //     else if (count === 4) {
            //         l1.style.opacity = 1;
            //         l2.style.opacity = 1;
            //         l3.style.opacity = 1;
            //         l4.style.opacity = 1;
            //         l5.style.opacity = 1;
            //         l6.style.opacity = 1;
            //     }
            //     else if (count >= 5) {
            //         l1.style.opacity = 1;
            //         l2.style.opacity = 1;
            //         l3.style.opacity = 1;
            //         l4.style.opacity = 1;
            //         l5.style.opacity = 1;
            //         l6.style.opacity = 1;
            //         l7.style.opacity = 1;
            //     }
            // }, 1000);

            // Get the state
            const state = Flip.getState(items);

            console.log("count", count)
            if (count < 5) {
                count++
                for (let i = 0; i >= 0; i--) {
                    grid.appendChild(grid.children[i | 0]);
                }
            }else return

            const firstElement = grid.children[0];
            const secondElement = grid.children[1];
            const thirdElement = grid.children[2];
            const fourthElement = grid.children[3];
            const fifthElement = grid.children[4];

            gsap.set(firstElement, {x: 240 , y: 20, fontSize: 36, paddingTop: 59, paddingLeft: 30, background: "#FFFFFF" });
            gsap.set(secondElement, {x: 280 , y: 20, fontSize: 20, paddingTop: 36, paddingLeft: 30, background: "#FFFFFF"});
            gsap.set(thirdElement, {x: 10 , y: 195, fontSize: 20, paddingTop: 36, paddingLeft: 30, background: "#FFFFFF"});
            gsap.set(fourthElement, {x: 50 , y: 0, fontSize: 20, paddingTop: 36, paddingLeft: 30, background: "#FFFFFF"});
            gsap.set(fifthElement, {x: 250 , y: 155, fontSize: 20, paddingTop: 36, paddingLeft: 30, background: "#FFFFFF"});
            timeline
                .to(fifthElement, {duration: .5, x: -240}, "+=1");

            if (count === 1) {
                // timeline
                //     .to(".l_1", {duration: .1, opacity: 1, x: -585}, "+=1")  ////// for example duration
                //     .to(".dot_1", {duration: .1, opacity: 1}, "+=1")

                timeline
                    .to(".l_1", {duration: .1, opacity: 1, x: -585}, "+=0")
                    .to(".dot_1", {duration: .1, opacity: 1}, "+=0")
                    .to(".l_9", {duration: .2, x: 382, y: 8.5, opacity: 1}, "+=0")
            }

            if (count === 2) {
                timeline
                    .to(".l_2", {duration: .2, opacity: 1, x: -202}, "+=0")
                    .to(".l_3", {duration: .2, opacity: 1, y: 62}, "+=0")
                    .to(".dot_2", {duration: .1, opacity: 1}, "+=0")
            }
            if (count === 3) {
                timeline
                    .to(".l_4", {duration: .1, opacity: 1, y: 222}, "+=0")
                    .to(".dot_3", {duration: .1, opacity: 1}, "+=0")
            }
            if (count === 4) {
                timeline
                    .to(".l_5", {duration: .1, opacity: 1, x: 305}, "+=0")
                    .to(".l_6", {duration: .1, opacity: 1, y: 200}, "+=0")
                    .to(".dot_4", {duration: .1, opacity: 1}, "+=0")
            }
            if (count === 5) {
                timeline
                    .to(".l_7", {duration: .1, opacity: 1, y: 42}, "+=0")
                    // .to(".l_8", {duration: .3, opacity: 1, x: 543}, "+=1") //if image animate
                    .to(".dot_5", {duration: .1, opacity: 1}, "+=0")
            }

            Flip.from(state, {
                duration: 1,
                easy: "power1.inOut",
                opacity: 0.2,
                scale: true
            });

            gsap.to(firstElement, {
                duration: 1,
                width: 587,
                height: 274,
                ease: "power1.inOut",
            });
            gsap.to(secondElement, {
                duration: 1,
                width: 271,
                height: 99,
                ease: "power1.inOut",

            });
            gsap.to(thirdElement, {
                duration: 1,
                width: 271,
                height: 99,
                ease: "power1.inOut",
            });
            gsap.to(fourthElement, {
                duration: 1,
                width: 291,
                height: 99,
                ease: "power1.inOut",
            });
            gsap.to(fifthElement, {
                duration: 1,
                width: 291,
                height: 142,
                ease: "power1.inOut",
            });


            // first element from left

            // gsap.from(firstElement, {
            //     // absolute: true,
            //     duration: 2.1,
            //     easy: "power1.inOut",
            //     // targets: ".rg_item1",
            //     opacity: 0,
            //     xPercent: -450
            // })


        };

        function handleShufflePrev () {

            // const l1:any = document.querySelector(".l_1");
            // const l2:any = document.querySelector(".l_2");
            // const l3:any = document.querySelector(".l_3");
            // const l4:any = document.querySelector(".l_4");
            // const l5:any = document.querySelector(".l_5");
            // const l6:any = document.querySelector(".l_6");
            // const l7:any = document.querySelector(".l_7");

            buttonPrev.disabled = true;
            // l1.style.opacity = 0;
            // l2.style.opacity = 0;
            // l3.style.opacity = 0;
            // l4.style.opacity = 0;
            // l5.style.opacity = 0;
            // l6.style.opacity = 0;
            // l7.style.opacity = 0;
            setTimeout(function(){
                buttonPrev.disabled = false;
            }, 2000);

            // setTimeout(function(){
            //     if (count === 0) {
            //         l1.style.opacity = 0;
            //     }
            //     else if (count === 1){
            //         l1.style.opacity = 1;
            //     }
            //     else if (count === 2) {
            //         l1.style.opacity = 1;
            //         l2.style.opacity = 1;
            //         l3.style.opacity = 1;
            //     }
            //     else if (count === 3) {
            //         l1.style.opacity = 1;
            //         l2.style.opacity = 1;
            //         l3.style.opacity = 1;
            //         l4.style.opacity = 1;
            //     }
            //     else if (count === 4) {
            //         l1.style.opacity = 1;
            //         l2.style.opacity = 1;
            //         l3.style.opacity = 1;
            //         l4.style.opacity = 1;
            //         l5.style.opacity = 1;
            //         l6.style.opacity = 1;
            //     }
            //     else if (count >= 5) {
            //         l1.style.opacity = 1;
            //         l2.style.opacity = 1;
            //         l3.style.opacity = 1;
            //         l4.style.opacity = 1;
            //         l5.style.opacity = 1;
            //         l6.style.opacity = 1;
            //         l7.style.opacity = 1;
            //     }
            // }, 1000);

            // Get the state
            const state = Flip.getState(items);

            console.log("count", count)
            if (count > 0) {
                count--
                for (let i = 0; i >= 0; i--) {
                    grid.insertBefore(grid.lastChild, grid.firstChild);
                }
            }else return

            const firstElement = grid.children[0];
            const secondElement = grid.children[1];
            const thirdElement = grid.children[2];
            const fourthElement = grid.children[3];
            const fifthElement = grid.children[4];

            gsap.set(firstElement, {x: 240 , y: 20, fontSize: 36, paddingTop: 59, paddingLeft: 30, background: "#FFFFFF" });
            gsap.set(secondElement, {x: 280 , y: 20, fontSize: 20, paddingTop: 36, paddingLeft: 30, background: "#FFFFFF"});
            gsap.set(thirdElement, {x: 10 , y: 195, fontSize: 20, paddingTop: 36, paddingLeft: 30, background: "#FFFFFF"});
            gsap.set(fourthElement, {x: 50 , y: 0, fontSize: 20, paddingTop: 36, paddingLeft: 30, background: "#FFFFFF"});
            gsap.set(fifthElement, {x: 250 , y: 155, fontSize: 20, paddingTop: 36, paddingLeft: 30, background: "#FFFFFF"});
            timeline
                .to(fifthElement, {duration: .5, x: -240}, "+=1");

            if (count === 0) {
                // timeline
                //     .to(".l_1", {duration: .1, opacity: 1, x: -585}, "+=1")  ////// for example duration
                //     .to(".dot_1", {duration: .1, opacity: 1}, "+=1")
                timeline
                    .to(".l_9", {duration: .1, x: 882, y: 8.5, opacity: 0}, "+=0")
                    .to(".dot_1", {duration: .1, opacity: 0}, "+=0")
                    .to(".l_1", {duration: 1, opacity: 0, x: -1240}, "+=0")


            }

            if (count === 1) {

                timeline
                    .to(".dot_2", {duration: .1, opacity: 0}, "+=0")
                    .to(".l_2", {duration: .1, opacity: 0, x: -1240}, "+=0")
                    .to(".l_3", {duration: .2, opacity: 0, y: 144}, "+=0")

            }
            if (count === 2) {
                timeline
                    .to(".dot_3", {duration: .1, opacity: 0}, "+=0")
                    .to(".l_4", {duration: .1, opacity: 0, y: 144}, "+=0")
            }
            if (count === 3) {
                timeline
                    .to(".dot_4", {duration: .1, opacity: 0}, "+=0")
                    .to(".l_5", {duration: .1, opacity: 0, x: 250}, "+=0")
                    .to(".l_6", {duration: .1, opacity: 0, y: 470}, "+=0")
            }
            if (count === 4) {
                timeline
                    .to(".dot_5", {duration: .1, opacity: 0}, "+=0")
                    .to(".l_7", {duration: .1, opacity: 0, y: 95}, "+=0")
                    // .to(".l_8", {duration: .3, opacity: 1, x: 543}, "+=1") //if image animate
            }

            Flip.from(state, {
                duration: 1,
                easy: "power1.inOut",
                opacity: 0.2,
                scale: true
            });

            gsap.to(firstElement, {
                duration: 1,
                width: 587,
                height: 274,
                ease: "power1.inOut",
            });
            gsap.to(secondElement, {
                duration: 1,
                width: 271,
                height: 99,
                ease: "power1.inOut",

            });
            gsap.to(thirdElement, {
                duration: 1,
                width: 271,
                height: 99,
                ease: "power1.inOut",
            });
            gsap.to(fourthElement, {
                duration: 1,
                width: 291,
                height: 99,
                ease: "power1.inOut",
            });
            gsap.to(fifthElement, {
                duration: 1,
                width: 291,
                height: 142,
                ease: "power1.inOut",
            });


            // first element from left

            // gsap.from(firstElement, {
            //     // absolute: true,
            //     duration: 2.1,
            //     easy: "power1.inOut",
            //     // targets: ".rg_item1",
            //     opacity: 0,
            //     xPercent: -450
            // })


        };

        const buttonNext:any = document.getElementById("right");
        buttonNext.addEventListener("click", handleShuffleNext);

        const buttonPrev:any = document.getElementById("left");
        buttonPrev.addEventListener("click", handleShufflePrev);


        return () => {
            buttonNext.removeEventListener("click", handleShuffleNext);
            buttonPrev.removeEventListener("click", handleShufflePrev);

        };
    }, []);

    return (
        <div className="roadmap-gsap">
            <button className="rg_btn_prev l_9" data-rg="grid-demo" id="left">
                previous
            </button>
            <button className="rg_btn" data-rg="grid-demo" id="right" >
                next
            </button>
            <div className="l_1"></div>
            <img src={Dot} alt="" className="dot_1"/>
            <div className="l_2"></div>
            <div className="l_3"></div>
            <img src={Dot} alt="" className="dot_2"/>
            <div className="l_4"></div>
            <img src={Dot} alt="" className="dot_3"/>
            <div className="l_5"></div>
            <div className="l_6"></div>
            <img src={Dot} alt="" className="dot_4"/>
            <div className="l_7"></div>
            <img src={RoadmapNext} alt="" className="l_8"/>
            <img src={RoadmapPrev} alt="" className="l_9"/>
            <img src={Dot} alt="" className="dot_5"/>

            <div className="grid" id="grid-demo" ref={gridRef}>


                <div className="rg_item1" ref={(el) => itemsRef.current.push(el)}>
                    <span className="item-title">January /2023</span>
                    <div className="rg_item-body">
                        <div className="rg_item-body-item">
                            <img src={RoadmapIcon} alt=""/>
                            <span>Your NFTs are stored in a digital wallet.</span>
                        </div>
                    </div>
                </div>

                <div className="rg_item2" ref={(el) => itemsRef.current.push(el)}>
                    <span className="item-title">February /2023</span>
                    <div className="rg_item-body">
                        <div className="rg_item-body-item">
                            <img src={RoadmapIcon} alt=""/>
                            <span>Your NFTs are stored in a digital wallet.</span>
                        </div>
                    </div>
                </div>

                <div className="rg_item3" ref={(el) => itemsRef.current.push(el)}>
                    <span className="item-title">March /2023</span>
                    <div className="rg_item-body">
                        <div className="rg_item-body-item">
                            <img src={RoadmapIcon} alt=""/>
                            <span>Your NFTs are stored in a digital wallet.</span>
                        </div>
                    </div>
                </div>

                <div className="rg_item4" ref={(el) => itemsRef.current.push(el)}>
                    <span className="item-title">April /2023</span>
                    <div className="rg_item-body">
                        <div className="rg_item-body-item">
                            <img src={RoadmapIcon} alt=""/>
                            <span>Your NFTs are stored in a digital wallet.</span>
                        </div>
                    </div>
                </div>
                <div className="rg_item5" ref={(el) => itemsRef.current.push(el)}>
                    <span className="item-title">May /2023</span>
                    <div className="rg_item-body">
                        <div className="rg_item-body-item">
                            <img src={RoadmapIcon} alt=""/>
                            <span>Your NFTs are stored in a digital wallet.</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RoadmapGsap;
