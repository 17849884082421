export const offerBlock = [
    {
        title: "Free registration",
        content: "All you need to register is your crypto wallet.",
    },
    {
        title: "Early access to promising NFT",
        content: "You will be the first to receive news about the release of the company's limited edition collections and the right to purchase NFTs for profitable investments.",
    },
    {
        title: "Secure NFT storage",
        content: "We do not store your NFTs on our platform; all purchased assets are kept in your personal crypto wallet.",
    },
    {
        title: "Security guaranteed",
        content: "Security for all transactions, with crypto wallet audits.",
    },
]

export const offerBlockAbout = [
    {
        title: "Free registration",
        content: "All you need to register is your crypto wallet.",
    },
    {
        title: "Secure NFT storage",
        content: "We do not store your NFTs on our platform; all purchased assets are kept in your personal crypto wallet.",
    },
    {
        title: "Security guaranteed",
        content: "Security for all transactions, with crypto wallet audits.",
    },
    {
        title: "Early access to promising NFT",
        content: "You will be the first to receive news about the release of the company's limited edition collections and the right to purchase NFTs for profitable investments.",
    },
    {
        title: "Seamless integration with other NFT platforms",
        content: "Integration with other NFT platforms (OpenSea, LooksRare, X2Y2, Magic Eden, etc.) and receiving orders from them ensures high asset liquidity.",
    },
    {
        title: "Trading at your price and through auctions",
        content: "Each user can sell their NFT at a fixed price or put their NFT up for auction. RED COST platform hosts descending price auctions.",
    },
    {
        title: "Access to an exclusive community of crypto investors",
        content: "Any RED COST user can join a private chat where investment strategies and new earning schemes for NFT assets are discussed.",
    },
]

export const offerBlockDiscover = [
    {
        title: "Purchase of NFT",
        content: "Buy NFT in the RED COST marketplace! hoose an asset and make a secure transaction in our marketplace. You can track changes in the value of selected assets and make objective investment decisions.",
    },
    {
        title: "Sale of NFT",
        content: "You can find buyers for your NFTs on our site. Place your assets in the RED COST marketplace, set your price and earn.",
    },
    {
        title: "Audit of cryptocurrency wallet",
        content: "RED COST ensures user security, including auditing cryptocurrency wallets. This allows platform participants to have confidence in the safety of their funds.",
    },
    {
        title: "Reduction auction",
        content: "RED COST provides all users with the opportunity to participate in a reverse auction. Its main distinction is that the asset price decreases until a buyer is found.",
    },
    {
        title: "NFT Burning",
        content: "On the RED COST platform, token holders have the option to burn tokens, reducing the overall supply and thereby increasing the value of the remaining assets.",
    },
    {
        title: "Staking",
        content: "Staking is a process that allows you to earn passive income by holding and locking up your crypto assets on the RED COST platform.",
    },
]