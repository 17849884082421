import React, {useState} from "react";
import MainComponent from "../components/MainComponent";
import RedCost from "../assets/images/red-cost.svg";
import RedCostMob from "../assets/images/red-cost-mob.svg";
import RedCost1 from "../assets/images/red-cost-1.svg";
import RedCost1Mob from "../assets/images/red-cost-1-mobile.png";
import RedCost2 from "../assets/images/red-cost-2.svg";
import RedCost2Mob from "../assets/images/red-cost-2-nobile.png";
import SecurityImage from "../assets/images/security-logo.svg"
import Security from "../components/Security";
import {socialItemsList, socialItemsListBig1, socialItemsListBig2} from "../constants/socialItems";
import bgImage from "../assets/bg-image.svg";
import bgImageMobile from "../assets/bg-image-mobile.svg";
import RoadmapWeb from "../components/RoadmapWeb";
import CountDown from "../components/CountDown";
import CountdownMain from "../components/countdown/CountdownMain";
import RoadmapGsap from "../components/RoadmapGsap";
import RoadmapMobileHome from "../components/RoadmapMobileHome";
import {offerBlock} from "../constants/textContent";
import {useNavigate} from "react-router-dom";

function AuthLayout () {

    const navigate = useNavigate()

    const handleClickSocialItem = (item:String) => {
        switch (item) {
            case "x":
                window.open("https://x.com/", '_blank');
                break;
            case "discord":
                window.open("https://discord.com/", '_blank');
                break;
            case "facebook":
                window.open("https://facebook.com/", '_blank');
                break;
            case "youtube":
                window.open("https://youtube.com/", '_blank');
                break;
            case "linkedin":
                window.open("https://linkedin.com/", '_blank');
                break;
           case "reddit":
                window.open("https://reddit.com/", '_blank');
                break;
           case "medium":
                window.open("https://medium.com/", '_blank');
                break;
           case "instagram":
                window.open("https://instagram.com/", '_blank');
                break;
            default:
                console.log(`Click on ${item} and navigate ${item}`)
        }

    }

    return (
        <div className="auth-layouth">
            <div className="bg-image">
                <img src={bgImage} alt="bg-image-web" className="bg-image-web"/>
                <img src={bgImageMobile} alt="bg-image-mobile" className="bg-image-mobile"/>
            </div>

            <div className="digital-art">
                <div className="header-side">
                    Enter The New World<br/>of Digital Art
                </div>
                <div className="bottom-side">
                    <div className="bottom-side-left">
                        <div className="bottom-side-left-header">
                            The marketplace for those who know<br/>the true value of digital assets.
                        </div>
                        <div className="bottom-side-left-header_buttons">
                            <div className="left-button">Explore now</div>
                            <div className="right-button">AirDrop</div>
                        </div>
                    </div>
                    <div className="auth-countdown">
                        <CountdownMain />
                    </div>
                </div>
            </div>
            <div id="firstMainComponent">
                <MainComponent
                    image={RedCost}
                    imageMobile={RedCostMob}
                    title={true}
                    titleText="ABOUT"
                    subtitle="RED COST"
                    content1={"RED COST is the official marketplace for valuing, buying and selling digital assets (NFT). Complies with all current cryptocurrency security requirements. All transactions are legal and transparent."}
                    content2={"We have created a safe and convenient platform for all like-minded people who understand the topic and want to earn safely on NFT."}
                    reverse={false}
                    buttonSize={true}
                    isInput={false}
                    buttonValue="WHITEPAPER"
                />
            </div>
            <div className="security-component">
                <div className="security-component-header">
                    <div className="line_1"></div>
                    <div className="security-component-title">we offer you</div>
                    <div className="line_1"></div>
                </div>
                <div className="security-group">
                    {offerBlock.map((offer,index)=> {
                        return <Security
                            key={index}
                            image={SecurityImage}
                            subtitle={offer.title}
                            content={offer.content}
                        />
                    })}
                </div>
                <div className="security-component-button">
                    Explore now
                </div>
            </div>

            <div className="roadmap-home">
                <div className="roadmap-home-header">
                    <div className="roadmap-home-header-line_1"></div>
                    <div className="roadmap-home-header-title">check our</div>
                </div>
                <div className="roadmap-home-header-subtitle">Roadmap</div>
                <div className="web-roadmap-display">
                    <RoadmapGsap />
                </div>
                <div className="mobile-roadmap-display">
                    <RoadmapMobileHome />
                </div>
            </div>
            <div className="lastMainComponent">
                <MainComponent
                    image={RedCost1}
                    imageMobile={RedCost1Mob}
                    title={true}
                    titleText="OUR"
                    subtitle="Presale"
                    content1={"We’re about to start the presale of our unique limited edition NFT collection! Right now, we’re issuing tokens for internal auctions on our platform. These tokens can be used as a payment method to purchase NFTs. We’ll announce the start date very soon! In the meantime, leave your contact details to be the first to hear about community news!"}
                    reverse={true}
                    buttonSize={false}
                    isInput={true}
                    buttonValue="Send"

                />
            </div>
            <div className="social-big-logo-group">
                <div className="security-component-header">
                    <div className="line_1"></div>
                    <div className="security-component-title">follow our socials</div>
                    <div className="line_1"></div>
                </div>
                <div className="social-group-component">
                    {socialItemsListBig1.map((item,key)=> {
                        return <div
                            // className="folow-item"
                            key={key}
                            onClick={() => handleClickSocialItem(item.name)}
                        >
                            <div className="svgbig-container">
                                <a href="#" rel="nofollow">{item.logosvg}</a>
                                <a href="#" rel="nofollow">{item.logoRed}</a>
                            </div>
                        </div>
                    })}
                    </div>
                <div className="social-group-component1">
                    {socialItemsListBig2.map((item,key)=> {
                        return <div
                            className="folow-itembig"
                            key={key}
                            onClick={() => handleClickSocialItem(item.name)}
                        >
                            <div className="svgbig-container">
                                <a href="#" rel="nofollow">{item.logosvg}</a>
                                <a href="#" rel="nofollow">{item.logoRed}</a>
                            </div>
                        </div>
                    })}
                </div>
            </div>
            <div className="lastMainComponent1">
                <MainComponent
                    image={RedCost2}
                    imageMobile={RedCost2Mob}
                    title={true}
                    titleText="important"
                    subtitle="For Investors"
                    content1={"Every one of our users can also become an investor. We offer the best conditions for growing and multiplying your crypto capital. On our platform, you can invest in limited edition NFT collections or participate in staking to earn passive income from your investments."}
                    buttonSize={false}
                    isInput={true}
                    buttonValue="Learn more"
                    func={() => navigate("./investors")}
                />
            </div>

        </div>
    )
}

export default AuthLayout;

