import React, {useEffect, useState} from "react";
import WebHeader from "./WebHeader";
import MobileHeader from "./MobileHeader";
import { useWeb3Modal, useWeb3ModalAccount } from "@web3modal/ethers/react";
import {balanceOfRDCToken, getBalance} from "../web3";

function Header() {
    const [balanceOfBnb, setBalanceOfBnb] = useState();
    const [balanceOfRdc, setBalanceOfRdc] = useState();

    // web3 connection
    const { address, chainId, isConnected } = useWeb3ModalAccount()
    const { open } = useWeb3Modal()

    localStorage.setItem("address", JSON.stringify(address));
    localStorage.setItem("chainId", JSON.stringify(chainId));
    localStorage.setItem("isConnected", JSON.stringify(isConnected));

    useEffect(() => {
        if (address) {
            getBalance(address).then((res:any) => {
                setBalanceOfBnb(res)
            });
            balanceOfRDCToken(address).then((res:any) => {
                setBalanceOfRdc(res)
            })
        }
    }, [address])

    return (
        <div className='header'>
            <WebHeader
                address={address}
                chainId={chainId}
                isConnected={isConnected}
                balanceOfBnb={balanceOfBnb}
                balanceOfRdc={balanceOfRdc}
                open={open}
            />
            <MobileHeader
                address={address}
                chainId={chainId}
                isConnected={isConnected}
                balanceOfBnb={balanceOfBnb}
                balanceOfRdc={balanceOfRdc}
                open={open}
            />

            {/*Connection Modal*/}
            {/*<Modal*/}
            {/*    centered*/}
            {/*    width={480}*/}
            {/*    open={profitModalOpen}*/}
            {/*    onCancel={() => setProfitModalOpen(false)}*/}
            {/*    footer={null}*/}
            {/*    className="mini-popup"*/}
            {/*>*/}
            {/*    <img src={PopupLogo} alt=""/>*/}
            {/*    <div className="title">Connect to RedCost</div>*/}
            {/*    <Input size="large" placeholder="Continue with email" suffix={<RightOutlined onClick={handleInputClick}/>} />*/}
            {/*    <div className="security-component-header mt-30">*/}
            {/*        <div className="line_2"></div>*/}
            {/*        <div className="security-component-title">or</div>*/}
            {/*        <div className="line_2 rotate"></div>*/}
            {/*    </div>*/}
            {/*    <div className="connections">*/}
            {/*        <div className="connections_item">*/}
            {/*            <img src={Metamask} alt=""/>*/}
            {/*            <span>MetaMask</span>*/}
            {/*        </div>*/}
            {/*        <div className="connections_item borders">*/}
            {/*            <img src={Coinbase} alt=""/>*/}
            {/*            <span>Coinbase Wallet</span>*/}
            {/*        </div>*/}
            {/*        <div className="connections_item">*/}
            {/*            <img src={WalletConnect} alt=""/>*/}
            {/*            <span>Wallet Connect</span>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*    <div className="more-wallets">More wallet options</div>*/}


            {/*</Modal>*/}

        </div>
    )
}

export default Header;
