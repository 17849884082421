import React from "react"
import bgImage from "../assets/bg-image.svg";
import bgImageMobile from "../assets/bg-image-mobile.svg";
import CountDown from "../components/CountDown";
import MainComponent from "../components/MainComponent";
import RedCost from "../assets/images/red-cost.svg";
import RedCostMob from "../assets/images/red-cost-mob.svg";
import RedCostCenter from "../assets/images/red-cost-center.svg";
import Walf from "../assets/walf.svg";
import Security from "../components/Security";
import SecurityImage from "../assets/images/security-logo.svg";
import RoadmapWeb from "../components/RoadmapWeb";
import { socialItemsList } from "../constants/socialItems";
import SecurityDiscover from "../components/SecurityDiscover";
import MainComponent2 from "../components/MainComponent2";
import {offerBlockDiscover} from "../constants/textContent";

function About() {

    return (
        <div className="discover">
            <div className="discover-text-block">
                <p className="discover-text">
                    Discover
                </p>
            </div>
            <div className="discover-first-item">

                <div className="discover-digital-art">
                    <div className="discover-header-side">
                        Enter The New World<br />of Digital Art
                    </div>
                    <div className="discover-bottom-side">
                        <div className="discover-bottom-side-left">
                            <div className="discover-bottom-side-left-header">
                                RED COST is a platform designed to make money <br/> from NFTs through various effective user activities through<br/> direct buying and selling, as well as through down auction, NFT burning and staking.
                            </div>
                            <div className="discover-bottom-side-left-header_buttons">
                                <div className="discover-left-button">Whitepaper</div>
                                <div className="discover-right-button">About</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="discover-first-item-mobile">
                <p className="discover-text">
                    Discover
                </p>

                <div className="discover-digital-art">
                    <div className="discover-header-side">
                        Enter The New World<br />of Digital Art
                    </div>
                    <div className="discover-bottom-side">
                        <div className="discover-bottom-side-left">
                            <div className="discover-bottom-side-left-header">
                                RED COST is a platform designed to make money <br/> from NFTs through various effective<br/> user activities through direct buying and selling,<br/> as well as through down auction,<br/> NFT burning and staking.
                            </div>
                            <div className="discover-bottom-side-left-header_buttons">
                                <div className="discover-left-button">Whitepaper</div>
                                <div className="discover-right-button">About</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="security-component">
                <div className="security-component-header">
                    <div className="line_1"></div>
                    <div className="security-component-title">we offer you</div>
                    <div className="line_1"></div>
                </div>
                <div className="discover-security-group">
                    {offerBlockDiscover.map((item,index) => {
                        return <SecurityDiscover
                            key={index}
                            image={SecurityImage}
                            subtitle={item.title}
                            content={item.content}
                        />
                    })}
                </div>
                <div className="security-component-button">
                    Explore now
                </div>
            </div>

            <div className="lastMainComponent3">
                <MainComponent
                    image={RedCost}
                    imageMobile={RedCostMob}
                    title={true}
                    titleText=""
                    subtitle="TiTle"
                    content1={"RED COST is the official marketplace for valuing, buying and selling digital assets (NFT). Complies with all current cryptocurrency security requirements. All transactions are legal and transparent."}
                    content2={"To create a safe and convenient platform for everyone who understands the topic and wants to earn safely on NFT."}
                    reverse={false}
                    buttonSize={true}
                    isInput={false}
                    buttonValue="Button"
                />
            </div>
            <div>
                <MainComponent2
                    image={RedCostCenter}
                    imageMobile={RedCostMob}
                    subtitle="TiTle"
                    content1={"RED COST is the official marketplace for valuing, buying and selling digital assets (NFT). Complies with all current cryptocurrency security requirements. All transactions are legal and transparent."}
                    content2={"To create a safe and convenient platform for everyone who understands the topic and wants to earn safely on NFT."}
                    content11={"RED COST is the official marketplace for valuing, buying and selling digital assets (NFT). Complies with all current cryptocurrency security requirements. All transactions are legal and transparent."}
                    buttonSize={true}
                    buttonValue="Button"
                />
            </div>

            <div className="lastMainComponent2">
                <MainComponent
                    image={RedCost}
                    imageMobile={RedCostMob}
                    title={true}
                    titleText=""
                    subtitle="TiTle"
                    content1={"RED COST is the official marketplace for valuing, buying and selling digital assets (NFT). Complies with all current cryptocurrency security requirements. All transactions are legal and transparent."}
                    content2={"To create a safe and convenient platform for everyone who understands the topic and wants to earn safely on NFT."}
                    reverse={true}
                    buttonSize={true}
                    isInput={false}
                    buttonValue="Button"
                />
            </div>
            <div className="lastMainComponent1">
                <MainComponent
                    image={RedCost}
                    imageMobile={RedCostMob}
                    title={true}
                    titleText=""
                    subtitle="TiTle"
                    content1={"RED COST is the official marketplace for valuing, buying and selling digital assets (NFT). Complies with all current cryptocurrency security requirements. All transactions are legal and transparent."}
                    content2={"To create a safe and convenient platform for everyone who understands the topic and wants to earn safely on NFT."}
                    reverse={false}
                    buttonSize={true}
                    isInput={false}
                    buttonValue="Button"
                />
            </div>

            <MainComponent2
                image={RedCostCenter}
                imageMobile={RedCostMob}
                subtitle="TiTle"
                content1={"RED COST is the official marketplace for valuing, buying and selling digital assets (NFT). Complies with all current cryptocurrency security requirements. All transactions are legal and transparent."}
                content2={"To create a safe and convenient platform for everyone who understands the topic and wants to earn safely on NFT."}
                content11={"RED COST is the official marketplace for valuing, buying and selling digital assets (NFT). Complies with all current cryptocurrency security requirements. All transactions are legal and transparent."}
                buttonSize={true}
                buttonValue="Button"
            />

            <div className="lastMainComponent">
                <MainComponent
                    image={RedCost}
                    imageMobile={RedCostMob}
                    title={true}
                    titleText=""
                    subtitle="TiTle"
                    content1={"RED COST is the official marketplace for valuing, buying and selling digital assets (NFT). Complies with all current cryptocurrency security requirements. All transactions are legal and transparent."}
                    content2={"To create a safe and convenient platform for everyone who understands the topic and wants to earn safely on NFT."}
                    reverse={true}
                    buttonSize={true}
                    isInput={false}
                    buttonValue="Button"
                />
            </div>
            <div className="discover-last-item">
                <div className="discover-last-item-digital-art">
                    <div className="discover-header-side">
                        Enter The New World<br />of Digital Art
                    </div>
                    <div className="discover-bottom-side">
                        <div className="discover-bottom-side-left">
                            <div className="discover-bottom-side-left-header">
                                The marketplace for those who know<br />the true value of digital assets.
                            </div>
                            <div className="discover-last-item-left-button">AirDrop</div>
                        </div>
                    </div>
                </div>
                <div className="walf-image-web">
                    <img src={Walf} alt="" />
                </div>
            </div>

        </div>
    )
}
export default About;