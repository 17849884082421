import React, { useEffect, useState } from "react";
import { Modal, Select } from "antd";
import NFT from "../assets/nft/nft_1.svg";
import { approveERC721Token, depositNFT, getNextNFTIndex, getNftInfo } from "../web3";
import { useNavigate } from "react-router-dom";
import Loading from "../auth/Loading";


function Sale() {
    const [approveModalOpen, setApproveModalOpen] = useState(false);
    const [formDetails, setFormDetails] = useState<any>(null);
    const [price, setPrice] = useState("0");
    const [chain, setChain] = useState("BNB");
    const [days, setDays] = useState(7);
    const [category, setCategory] = useState("All");
    const [tokenAddress, setTokenAddress] = useState("");
    const [tokenId, setTokenId] = useState(null);
    const [description, setDescription] = useState(null);
    const [priceBNB, setPriceBNB] = useState<any>("0000");
    const [priceUSD, setPriceUSD] = useState<any>("0000");
    const [nftInfo, setNftInfo] = useState<any>(null);
    const [transactionApprove, setTransactionApprove] = useState<any>();
    const [nextNftIndex, setNextNftIndex] = useState<any>("");
    const [loading, setLoading] = useState<boolean>(false);
    let walletAddress: any = localStorage.getItem("address")
    let custodianContractAddress = "0xA0C1bdf09696C4542258e0f5e666B8824454372A";

    const navigate = useNavigate()
    useEffect(() => {
        if (tokenAddress && tokenId) {
            getNftInfo(tokenAddress, tokenId).then((res) => {
                setNftInfo(res)
            })
        }
        console.log("useEFFFFFECT", nftInfo)
    }, [tokenAddress, tokenId, nftInfo?.token_uri]);


    console.log("kkkkkkkkkkkkkkk", walletAddress)
    getNextNFTIndex().then((res) => {
        setNextNftIndex(res)
    })

    const setApiNftDetail = async () => {
        // const newNftData = {
        //     method: "POST",
        //     headers: {
        //         "Content-Type": "application/json",
        //     },
        //     body: JSON.stringify(formDetails),
        // }
        //     ;
        // fetch("https://red-cost.com/public/public/nfts/data", newNftData).then(
        //     (response) => response.json()
        // );
    };

    const onFinish = async (...value: any) => {
        value.price = price;
        value.chain = chain;
        value.days = days;
        value.category = category;
        value.tokenAddress = tokenAddress;
        value.tokenId = tokenId;
        value.description = description;
        if (price && chain && days && category && tokenAddress && tokenId && description) {
            let postDetails = {
                category: category,
                chain: chain,
                days: days,
                description: description,
                price: price,
                tokenAddress: tokenAddress,
                tokenId: tokenId,
                walletAddress: walletAddress.slice(1, -1),
                isActive: false,
                token_name: nftInfo?.token_name,
                token_symbol: nftInfo?.token_symbol,
                token_uri: nftInfo?.token_uri,
                tokenIndex: nextNftIndex
            }
            setFormDetails(postDetails);
            console.log("Approve", postDetails);
            //TODO set formDetails to backend

            setLoading(true)

            await approveERC721Token(tokenAddress, custodianContractAddress, tokenId).then((res) => {
                console.log("tr_RES", res?.status);
                if (res?.status === true) {
                    // Add a delay using setTimeout
                    setTimeout(() => {
                        depositNFT(tokenAddress, tokenId).then((result) => {
                            console.log("deposit Res Status", result?.status);
                            if (result?.status === true) {
                                const newNftData = {
                                    method: "POST",
                                    headers: {
                                        "Content-Type": "application/json",
                                    },
                                    body: JSON.stringify(postDetails),
                                };
                                fetch("https://red-cost.com/public/public/nfts/data", newNftData).then(
                                    (response) => response.json()
                                );
                                setTransactionApprove(res);
                                navigate("/assets");
                            }
                        });
                    }, 4000); // 2000ms = 2 seconds delay
                }
            });
        }
        setLoading(false)
    };

    const handleApprove = () => {
        // console.log("Approve", formDetails);
        // //TODO set formDetails to backend
        //
        // approveERC721Token(tokenAddress, custodianContractAddress, tokenId).then((res) => {
        //     if (res) {
        //         setApiNftDetail();
        //         navigate("/assets")
        //         setTransactionApprove(res)
        //     }
        // })

        setApproveModalOpen(false);
    }


    const handleCancel = () => {
        setApproveModalOpen(false);
    }

    const selectedPrice = (event: any) => {
        setPrice(event.target.value)
    };

    const selectedChain = (event: any) => {
        setChain(event)
    };

    const selectedDays = (days: any) => {
        setDays(days)
    };

    const selectedCategory = (category: any) => {
        setCategory(category)
    };

    const handleTokenAddress = (event: any) => {
        setNftInfo(null)
        let address = event.target.value
        if (address.length >= 16) {
            setTokenAddress(address)
        }
    };

    const handleTokenId = (event: any) => {
        setNftInfo(null)
        setTokenId(event.target.value)
    };

    const handleDescription = (event: any) => {
        setDescription(event.target.value)
    }

    return (
        <>
        {
            loading ? <Loading/> :
    <div className="sale">
        <div className="sale-left-side">
            <div className="sale-left-side_title">List for sale</div>
            <div className="add-nft-form">
                <div className="add-nft-form_item">
                    <div className="add-nft-form_item__title">
                        Set a price
                    </div>
                    <div className="set-price-group">
                        <input type="text" className="set-price-input" placeholder="Amount"
                               onChange={selectedPrice}/>
                        <Select
                            onChange={selectedChain}
                            className="set-price-input-select"
                            defaultValue="BNB"
                            options={[
                                {value: 'BNB', label: 'BNB'},
                                {value: 'Ch1', label: 'Ch1'},
                                {value: 'Ch2', label: 'Ch2'},
                            ]}
                        />
                    </div>
                </div>
                <div className="add-nft-form_item">
                    <div className="add-nft-form_item__title">
                        Period
                    </div>
                    <Select
                        onChange={selectedDays}
                        className="period-selector"
                        defaultValue="7 days"
                        options={[
                            {value: '7', label: '7 days'},
                            {value: '14', label: '14 days'},
                            {value: '28', label: '28 days'},
                        ]}
                    />
                </div>
                <div className="add-nft-form_item">
                    <div className="add-nft-form_item__title">
                        Category
                    </div>
                    <Select
                        onChange={selectedCategory}
                        className="period-selector"
                        defaultValue="All"
                        options={[
                            {value: 'All', label: 'All'},
                            {value: 'Art', label: 'Art'},
                            {value: 'Gaming', label: 'Gaming'},
                            {value: 'Membership', label: 'Membership'},
                            {value: 'PFPs', label: 'PFPs'},
                            {value: 'Photography', label: 'Photography'},
                            {value: 'Music', label: 'Music'},
                        ]}
                    />
                </div>
                <div className="token-info">
                    <div className="add-nft-form_item w-100">
                        <div className="add-nft-form_item__title">
                            Token address
                        </div>
                        <input type="text" className="token-info-input" onInput={handleTokenAddress}
                               value={tokenAddress ? (tokenAddress.slice(0, 14) + "....." + tokenAddress.slice(27, 42)) : ""}/>
                    </div>
                    <div className="add-nft-form_item w-100">
                        <div className="add-nft-form_item__title">
                            Token ID
                        </div>
                        <input type="text" className="token-info-input" onInput={handleTokenId}/>
                    </div>
                </div>
                <div className="add-nft-form_item">
                    <div className="add-nft-form_item__title">
                        NFT description
                    </div>
                    <textarea className="nft-description" placeholder="Add a few words about NFT:"
                              onInput={handleDescription}/>
                </div>
                <div className="add-nft-form_item">
                    <div className="add-nft-form_item__title">
                        Summary
                    </div>
                    <div className="summary">
                        <div className="summary_top">
                            <div className="summary_top__item">
                                <span>Listing price</span>
                                <span>{price}</span>
                            </div>
                            <div className="summary_top__item">
                                <span>Creator earnings</span>
                                <span>xxx</span>
                            </div>
                            <div className="summary_top__item">
                                <span>Service fee</span>
                                <span>3%</span>
                            </div>
                        </div>
                        <div className="summary_bottom">
                            <div className="summary_bottom_left">Total potential earnings</div>
                            <div className="summary_bottom_right">
                                <div
                                    className="summary_bottom_right_crypto">{price} RDC
                                </div>
                                <div
                                    className="summary_bottom_right_usd">${priceUSD.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}</div>
                            </div>
                        </div>

                    </div>
                </div>
                <button onClick={onFinish} className="listing-button">Approve listing</button>
            </div>

        </div>

        <div className="sale-right-side">
            <div className="sale-mob-title">List for sale</div>
            <div className="sales-nft-card">
                <div className="sales-nft-card_nftimage">
                    {tokenAddress && tokenId ? <img src={nftInfo?.token_uri}/> : ""}
                </div>
                <div className="sales-nft-card_footer">
                    <div className="sales-nft-card_footer_top">
                        <span className="sales-nft-card_footer_top_price1">{nftInfo?.token_name}</span>
                        <span className="sales-nft-card_footer_top_price2">{nftInfo?.token_name}</span>
                    </div>
                    <div className="sales-nft-card_footer_bottom">
                        {price} RDC
                    </div>
                </div>
            </div>
            <button className="accept-offer" onClick={onFinish}>
                Accept offer {price} RDC
            </button>
        </div>

        <Modal
            centered
            width={609}
            open={approveModalOpen}
            onCancel={() => setApproveModalOpen(false)}
            footer={null}
            className="approve-popup"
        >
            {/* <div className="approve-listing-modal-title">Approve listing</div>
                <div className="approve-listing-modal-content">
                    <div className="approve-listing-modal-content_left">
                        <img src={nftInfo?.token_uri} alt="" className="approve-listing-modal-content_left_nft" />
                        <div className="approve-listing-modal-content_left_nft-info">
                            <div className="approve-listing-modal-content_left_nft-info_top">
                                <div
                                    className="approve-listing-modal-content_left_nft-info_top_price1">{nftInfo?.token_name}</div>
                                <div
                                    className="approve-listing-modal-content_left_nft-info_top_price2">{nftInfo?.token_symbol}</div>
                            </div>
                            <div className="approve-listing-modal-content_left_nft-info_chain">
                                Chain: {"BNB"}
                            </div>

                        </div>
                    </div>

                    <div className="approve-listing-modal-content_right">
                        <div className="approve-listing-modal-content_right_price-bnb">
                            {priceBNB} BNB
                        </div>
                        <div className="approve-listing-modal-content_right_price-usd">
                            ${priceUSD.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                        </div>

                    </div>

                </div> */}
            {/* <div className="approve-listing-modal-buttons">
                    <button className="approve-listing-modal-buttons_cancel" onClick={handleCancel}>Cancel</button>
                    <button className="approve-listing-modal-buttons_approve" onClick={handleApprove}>Approve</button>
                </div> */}
        </Modal>

    </div>
}
        </>)
};

export default Sale;