import React, { useEffect, useState } from "react";
import NFT6 from "../assets/nft/nft_6.svg";
import CollectionStar from "../assets/collection-star.svg";
import Views from "../assets/views.svg";
import Favorites from "../assets/favorites.svg";
import FavoritesRed from "../assets/favorite-red.svg";
import Category from "../assets/category.svg";
// import CountdownSales from "../components/CountdownSales";
import Countdown from 'react-countdown';
import Basket from "../assets/icons/basket.svg";
import Description from "../assets/icons/description.svg"
import { Dropdown, Space } from "antd";
import type { MenuProps } from 'antd';
import DetailsIcon from "../assets/icons/details-icon.svg";
import ArrowDown from "../assets/icons/arrow-down-grey.svg";
import ArrowUp from "../assets/icons/arrow-up-grey.svg";
import PriceHistory from "../assets/icons/price-history.svg";
import ChartLine from "../assets/icons/chart-hover-line.svg";
import CountdownSmall from "../components/countdown/CountdownSmall";
import { auctionData, buyNFT, decreaseAuctionPrice, getAuctionData, getNFT } from "../web3";
import { useWeb3ModalAccount } from '@web3modal/ethers/react'
import Loading from "../auth/Loading";
import ArrowUpRed from "../assets/icons/arrow-up-red.svg";

function NFTDetails() {
    const [views, setViews] = useState<any>("000");
    const [favorites, setFavorites] = useState<any>();
    const [priceBNB, setPriceBNB] = useState<number>(1000);
    const [priceUSD, setPriceUSD] = useState<number>(5000);
    const [openDropdown, setOpenDropdown] = useState<any>();
    const [nftItem, setNftItem] = useState<any>();
    const { address, chainId, isConnected } = useWeb3ModalAccount();
    const [loading, setLoading] = useState<boolean>(false);
    const [deceasePrice, setDeceasePrice] = useState<any>()
    const [isFavorite, setIsFavorite] = useState<any>(true)

    let getItemData: any = localStorage.getItem("myNFT");
    let item = JSON.parse(getItemData);
    let walletAddress: any = localStorage.getItem("address");
    console.log("ADDRESSSSSSS>>>???", walletAddress)


    useEffect(()    => {
        let cat: any = localStorage.getItem("myNFT");
        if (cat) {
            var auctionInfo = JSON.parse(cat)
            setNftItem(auctionInfo)
            getNftPrice()
            
        }
    }, [nftItem?.token_index,deceasePrice,address]);

    useEffect(() => {
        getAllFavorites();
        getAllViews()        
    }, [])

    console.log("sssss", nftItem);

    function formatTime(date: any) {
        let hours = date.getHours();
        const minutes = date.getMinutes();
        const ampm = hours >= 12 ? 'PM' : 'AM';

        // Convert 24-hour format to 12-hour format
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'

        // Add leading zero to minutes if needed
        const minutesStr = minutes < 10 ? '0' + minutes : minutes;

        return `${hours}:${minutesStr} ${ampm}`;
    }

    const currentDate: any = new Date(Number(nftItem?.auction_end_date) * 1000);

    const currentDateFromCountdown = new Date(currentDate);
    const timestampFromCountdown = Math.floor(currentDateFromCountdown.getTime() / 1000);
    console.log("timestamp",timestampFromCountdown); // Output: 1729108464

    // 1731597699000
    console.log("currentDate",currentDate.toLocaleDateString())

    const renderer = ({ days, hours, minutes, seconds, completed }: any) => {
        if (completed) {
            // Render a completed state
            return <p className="time-text">00:00:0000</p>;
        } else {
            // Render a countdown
            return <p className="time-text">{hours}:{minutes}:{seconds}</p>;
        }
    };

    const handleBuyNFT = () => {
        console.log(nftItem?.token_index);
        setLoading(true)
        
        buyNFT(nftItem?.token_index).then((res)=>{
            if (res?.status === true) {
                const data = {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ walletAddress: item.wallet_address, tokenId: item.token_id, tokenAddress: item.token_address }),
                }
                    ;
                fetch("https://red-cost.com/public/public/nft/cancel", data).then(
                    (response) => response.json()
                );
            }
        })
        setLoading(false)
    }

    const handleDropNFT = async () => {
        setLoading(true)
        await decreaseAuctionPrice(nftItem?.token_index).then((res) => {
            console.log("Drop status",res?.status);
            if(res?.status === true){
                auctionData(nftItem?.token_index).then((res) => {
                    setDeceasePrice(res?.currentPrice)  
                })
            }
          
        });
        setLoading(false)
    }

    const onOpenChange = (e: any) => {
        setOpenDropdown(e)
    };

    const getAllFavorites = () => {
        const data = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                walletAddress: item?.wallet_address,
                tokenAddress: item?.token_address,
                tokenId: item?.token_id,
                tokenIndex: item?.token_index,
            }),
        };
        fetch("https://red-cost.com/public/public/favorites", data)
            .then((response) => response.json())
            .then(resp => setFavorites(resp.count))
    };

    const getAllViews = () => {
        const data = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                walletAddress: item?.wallet_address,
                tokenAddress: item?.token_address,
                tokenId: item?.token_id,
                tokenIndex: item?.token_index,
            }),
        };
        fetch("https://red-cost.com/public/public/views", data)
            .then((response) => response.json())
            .then(resp => setViews(resp.views_count))
    };

    const handleFavorite = () => {
        const data = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                walletAddress: walletAddress.slice(1, -1),
                tokenAddress: nftItem?.token_address,
                tokenId: nftItem?.token_id,
                tokenIndex: nftItem?.token_index,
                favorite: isFavorite
            }),
        };
        fetch("https://red-cost.com/public/public/favorites/data", data)
            .then((response) => response.json())
            .then((res => {
                setFavorites(res.count)
                console.log("ressssss=>", res)
            }))
    };


    const setNftPrice = (price: any) => {
        let account:any = localStorage.getItem('address')
        let walletAddress: any = account.slice(1, -1).toString();
        const newNftData = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ walletAddress: walletAddress, tokenAddress: nftItem?.token_address, tokenId: nftItem?.token_id, tokenIndex: nftItem?.token_index, droppedPrice: Number(price) /10**18 })
        };
        fetch("https://red-cost.com/public/public/price/data", newNftData).then(
            (response) => response.json()
        );
    }

    const getNftPrice = async () => {
        let account:any = localStorage.getItem('address')
        let walletAddress: any = account.slice(1, -1).toString();
        const getNewNftData = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ walletAddress: address, tokenAddress: nftItem?.token_address, tokenId: nftItem?.token_id, tokenIndex: nftItem?.token_index })
        };
        try {
            const response = await fetch("https://red-cost.com/public/public/price", getNewNftData);

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const data = await response.json(); // Await the JSON response
            setDeceasePrice(data?.price)
            console.log("Response data:", data); // Log the parsed data

            return data; // Return the data if needed
        } catch (error) {
            console.error("Error fetching NFT data:", error);
        }
    }

    const items: MenuProps['items'] = [
        {
            key: '1',
            label: <div className="dropdown-items">
                <div className="dropdown-items_left">Contract Address</div>
                <div className="dropdown-items_right">{(nftItem?.token_address)?.slice(0, 15) + "..." + (nftItem?.token_address)?.slice(30, 45)}</div>
            </div>
        },
        {
            key: '2',
            label: <div className="dropdown-items">
                <div className="dropdown-items_left">Token ID</div>
                <div className="dropdown-items_right">{nftItem?.token_id}</div>
            </div>
        },
        {
            key: '3',
            label: <div className="dropdown-items">
                <div className="dropdown-items_left">Token Standard</div>
                <div className="dropdown-items_right">ERC-721</div>
            </div>
        },
        {
            key: '4',
            label: <div className="dropdown-items">
                <div className="dropdown-items_left">Chain</div>
                <div className="dropdown-items_right">{nftItem?.chain}</div>
            </div>
        },
    ];


    return (
        <>
            {
                loading ? <Loading /> :
                    <div className="nft-details-main">
                        <div className="nft-details-first">
                            <div className="nft-details-first_left">
                                <div className="nft-details-mobile-header">
                                    <div className="nft-details-mobile-header_collection-name">
                                        <img src={CollectionStar} alt="CollectionStar" />
                                        <span>{nftItem?.token_name}</span>
                                    </div>
                                    <div className="nft-details-mobile-header_owner-name">
                                        <div className="nft-details-mobile-header_owner-name__name">{nftItem?.token_name}</div>
                                        <div className="nft-details-mobile-header_owner-name__owner">Owned
                                            by <span>@{nftItem?.token_name}</span></div>
                                    </div>
                                </div>
                                <img src={nftItem?.token_uri} alt="" />
                            </div>
                            <div className="nft-details-first_right">
                                <div className="nft-details-first_right__name">{nftItem?.token_name}</div>
                                <div className="nft-details-first_right__collection-details">
                                    <div className="nft-details-first_right__collection-details___owner">Owned
                                        by <span>@{nftItem?.token_name}</span></div>
                                    <div className="nft-details-first_right__collection-details___collection-name">
                                        <img src={CollectionStar} alt="CollectionStar" />
                                        <span>{nftItem?.token_name}</span>
                                    </div>
                                </div>
                                <div className="rating mt-30">
                                    <div className="rating_items">
                                        <img src={Views} alt="" />
                                        <span>{views} views</span>
                                    </div>
                                    <div className="rating_items">
                                        <img src={FavoritesRed} alt="" style={{ cursor: "pointer" }} onClick={handleFavorite} />
                                        <span>{favorites} favorites</span>
                                    </div>
                                    <div className="rating_items">
                                        <img src={Category} alt="" />
                                        <span>{nftItem?.category}</span>
                                    </div>
                                </div>
                                <div className="sale-end mt-30">
                                    <span>Sale ends</span>
                                    {/*<Countdown*/}
                                    {/*    date={new Date(currentDate)}*/}
                                    {/*    renderer={renderer}*/}
                                    {/*/>*/}
                                    <p className="time-text">{new Date(currentDate).toLocaleDateString()}</p>
                                    <span> at </span>
                                    <div> {formatTime(currentDate)} </div>
                                </div>
                                <div className="main-countdown">
                                    <CountdownSmall
                                        date={timestampFromCountdown}
                                    // date={Number(nftItem?.auction_end_date) * 1000}
                                    />
                                </div>

                                <div className="collection-actions">
                                    <div className="collection-actions_title">Current price</div>
                                    <div className="collection-actions_current-price">
                                        <div
                                            className="collection-actions_current-price_bnb">{deceasePrice} RDC
                                        </div>
                                        {/* <div
                                            className="collection-actions_current-price_usd">${priceUSD.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}</div> */}
                                    </div>
                                    <div className="nft-buy-buttons-gropup">
                                        <div className="nft-buy-button" onClick={handleBuyNFT}>
                                            <img src={Basket} alt="" />
                                            <span>Buy</span>
                                        </div>
                                        <div className="nft-drop-button" onClick={handleDropNFT}>
                                            <span>Drop the rate</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="nft-details-second">
                            <div className="nft-details-second_left">
                                <div className="nft-details-second_left_header">
                                    <img src={Description} alt="" />
                                    <span>Description</span>
                                </div>
                                <div className="nft-details-second_left_description">
                                    <div className="scroll-div">
                                        <span>{nftItem?.description}</span>
                                    </div>
                                </div>
                                <Dropdown
                                    menu={{ items }}
                                    trigger={["click"]}
                                    onOpenChange={onOpenChange}
                                    className="details-dropdown"
                                >
                                    <span onClick={(e) => e.preventDefault()}>
                                        <Space>
                                            <div className="dropdown-left">
                                                <img src={DetailsIcon} alt="" />
                                                <span>Details</span>
                                            </div>
                                            {/*{openDropdown ? <img src={ArrowUp} alt=""/> : <img src={ArrowDown} alt=""/>}*/}
                                            <img src={ArrowUp} alt="up"
                                                className={openDropdown ? "rotate-arrow-transition" : "rotate-arrow-transition rotate-arrow"} />
                                        </Space>
                                    </span>
                                </Dropdown>
                            </div>
                            <div className="nft-details-second_right">
                                <div className="nft-details-second_right_header">
                                    <img src={PriceHistory} alt="" />
                                    <span>Price History</span>
                                </div>
                                <div className="nft-details-second_right_description">
                                    <div className="chart-content">
                                        <div className="left-vertical">Volume (ETH)</div>
                                        {[1, 2, 3, 4].map((item) => <div className="chart-content_item" key={item}>
                                            <div className="chart-content_price">0.0</div>
                                            <div className="chart-content_line"></div>
                                            <div className="chart-content_price">0.0</div>
                                        </div>)}
                                        <div className="right-vertical">Average price<br />(ETH)</div>

                                        <div className="chart">
                                            <img src={ChartLine} alt="" className="line-image" />
                                            <div className="chart-info">
                                                <div className="chart-info_header">0.00 ETH</div>
                                                <div className="chart-info_content">
                                                    Avg. price: 0,000 ETH
                                                    <br />
                                                    Num. sales: 0
                                                    <br />
                                                    May 27 at 0:00 AM
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            }
        </>)
}

export default NFTDetails;