import React from "react"

function Error () {
    return (
        <div className="error">
            <div className="error-404">404</div>
            <div className="error-text">OOOPS! PAGE NOT FOUND</div>
        </div>
    )
}

export default Error;