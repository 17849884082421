import React, {createRef, RefObject, useState} from "react";
import { Carousel } from "antd";
import {CarouselRef} from "antd/es/carousel";
import Prev from "../assets/icons/prev-line.svg";
import Next from "../assets/icons/next-line.svg";
import RoadmapIcon from "../assets/icons/roadmap-text-icon.svg";
import DownIcon from "../assets/icons/arrow-down-grey.svg";
import UpIcon from "../assets/icons/arrow-up-grey.svg";


const RoadmapMobileAbout = () => {
    const [more, setMore] = useState(false)
    const carouselRef: RefObject<CarouselRef> = createRef<CarouselRef>();

    const onChangeNext = () => {
        setMore(false)
        carouselRef.current?.next();
    };
    const onChangePrev = () => {
        setMore(false)
        carouselRef.current?.prev();
    };
    const handleReadMore = () => {
        setMore(!more)
    };

    return (
        <div className="mobile-roadmap-home">

            <div onClick={onChangePrev} className="previous">
                <span>Previous</span>
                <img src={Prev} alt=""/>
            </div>
            <div onClick={onChangeNext} className="next">
                <span>Next</span>
                <img src={Next} alt=""/>
            </div>
            <Carousel
                dots={false}
                ref={carouselRef}
                effect="fade"
                infinite={false}
                speed={700}
                rootClassName="carousel-mobile-roadmap"
            >
                <div className={!more ? "mobile-slider-content": "h-100"}>
                    <div className="mobile-roadmap-title">March<span>/2023</span></div>

                    <div className="mobile-slider-content_item">
                        <img src={RoadmapIcon} alt=""/>
                        <span>Your NFTs are stored in a digital wallet.</span>
                    </div>
                    <div className="mobile-slider-content_item">
                        <img src={RoadmapIcon} alt=""/>
                        <span>Your NFTs are stored in a digital wallet.</span>
                    </div>
                    <div className="mobile-slider-content_item">
                        <img src={RoadmapIcon} alt=""/>
                        <span>Your NFTs are stored in a digital wallet.</span>
                    </div>

                    <div className="mobile-slider-content_item">
                        <img src={RoadmapIcon} alt=""/>
                        <span>Your NFTs are stored in a address.</span>
                    </div>
                    <div className="mobile-slider-content_item">
                        <img src={RoadmapIcon} alt=""/>
                        <span>Your NFTs are stored in a digital.</span>
                    </div>
                    <div className="mobile-slider-content_item">
                        <img src={RoadmapIcon} alt=""/>
                        <span>Your NFTs are stored in a digital wallet.</span>
                    </div>
                    <div className="mobile-slider-content_item">
                        <img src={RoadmapIcon} alt=""/>
                        <span>Your NFTs are stored in a digital wallet.</span>
                    </div>
                    <div className="mobile-slider-content_item">
                        <img src={RoadmapIcon} alt=""/>
                        <span>Your NFTs are stored in a digital wallet.</span>
                    </div>
                    <div className="mobile-slider-content_item">
                        <img src={RoadmapIcon} alt=""/>
                        <span>Your NFTs are stored in a digital wallet.</span>
                    </div>
                </div>
                <div className={!more ? "mobile-slider-content": "h-100"}>
                    <div className="mobile-roadmap-title">April<span>/2023</span></div>

                    <div className="mobile-slider-content_item">
                        <img src={RoadmapIcon} alt=""/>
                        <span>stored in a digital wallet.</span>
                    </div>
                    <div className="mobile-slider-content_item">
                        <img src={RoadmapIcon} alt=""/>
                        <span>Your NFTs are digital wallet.</span>
                    </div>
                    <div className="mobile-slider-content_item">
                        <img src={RoadmapIcon} alt=""/>
                        <span>Your NFTs in a digital wallet.</span>
                    </div>
                    <div className="mobile-slider-content_item">
                        <img src={RoadmapIcon} alt=""/>
                        <span>Your NFTs digital wallet.</span>
                    </div>
                    <div className="mobile-slider-content_item">
                        <img src={RoadmapIcon} alt=""/>
                        <span>Your NFTs are stored in a digital wallet.</span>
                    </div>
                    <div className="mobile-slider-content_item">
                        <img src={RoadmapIcon} alt=""/>
                        <span>Your NFTs are stored in a digital wallet.</span>
                    </div>
                    <div className="mobile-slider-content_item">
                        <img src={RoadmapIcon} alt=""/>
                        <span>Yourdigital wallet.</span>
                    </div>
                    <div className="mobile-slider-content_item">
                        <img src={RoadmapIcon} alt=""/>
                        <span>Your NFTs are stored in a digital wallet.</span>
                    </div>
                </div>
            </Carousel>
            {!more ? <img src={DownIcon} onClick={handleReadMore} style={{marginTop: "16px"}}/>:
                <img src={UpIcon} onClick={handleReadMore} style={{marginTop: "16px"}} />}

        </div>
    )
};

export default RoadmapMobileAbout;
