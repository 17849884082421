import React from "react";
import ActiveGreen from "../assets/icons/active-green.svg"
import Moderation from "../assets/icons/onmoderation.svg"

function AssetsNftCard (props:any) {

    return (
        <div className={props.statusView? "assets-nft-card" : props.itemIndex == props.index ? "assets-nft-card cursor-pointer border-red": "assets-nft-card cursor-pointer"} onClick={props.func}>
            <div className="assets-nft-card_nftimage" >
                <img src={props.image} alt=""/>
                {props.statusView ? <div className="assets-nft-card_nftimage_status">
                    {props.active ? <>
                        <img src={ActiveGreen} alt=""/>
                        <span className="active">Active</span>
                    </> : <>
                        <img src={Moderation} alt=""/>
                        <span className="moderation">On moderation</span>
                    </>
                    }
                </div>:""}
            </div>
            <div className="assets-nft-card_footer">
                <div className="assets-nft-card_footer_title">
                    {props.name}
                </div>
                <div className="assets-nft-card_footer_details-group">
                    <div className="assets-nft-card_footer_details">
                        <div className="assets-nft-card_footer_details_top">{props.owners}</div>
                        <div className="assets-nft-card_footer_details_bottom">{props.ownwersCount} ({props.percent}%)</div>
                    </div>
                    <div className="assets-nft-card_footer_details">
                        <div className="assets-nft-card_footer_details_top">{"24"}H VOLUME</div>
                        <div className="assets-nft-card_footer_details_bottom">{}ETH</div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default AssetsNftCard;