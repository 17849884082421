import React from "react";
import {Select} from "antd";
import NFT from "../assets/nft/nft_1.svg";

function NFTInfo () {
    return (
        <div className="info">
            <div className="info_title">Info</div>
            <div className="info_item">
                <div className="info_item_title">
                    1. Header
                </div>
                <div className="info_item_content">
                    <div className="info_item_content_first-block">
                        <span>
                            RED COST is the official marketplace for valuing, buying and selling digital assets (NFT). Complies with all current cryptocurrency security requirements. All transactions are legal and transparent. To create a safe and convenient platform for everyone who understands the topic and wants to earn safely on NFT.
                        </span>
                    </div>
                    <div className="info_item_content_second-block">
                        <div className="add-nft-form_item">
                            <div className="add-nft-form_item__title">
                                Set a price
                            </div>
                            <div className="set-price-group">
                                <input type="text" className="set-price-input" placeholder="Amount" disabled/>
                                <Select
                                    disabled
                                    className="set-price-input-select"
                                    defaultValue="BNB"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="info_item">
                <div className="info_item_title">
                    2. Header
                </div>
                <div className="info_item_content">
                    <div className="info_item_content_first-block">
                        <span>
                            RED COST is the official marketplace for valuing, buying and selling digital assets (NFT). Complies with all current cryptocurrency security requirements. All transactions are legal and transparent. To create a safe and convenient platform for everyone who understands the topic and wants to earn safely on NFT.
                        </span>
                    </div>
                    <div className="list-for-sale-info">
                        <div className="sale margin-top-0 w-100">
                        <div className="sale-left-side">
                            <div className="sale-left-side_title">List for sale</div>
                            <div className="add-nft-form">
                                <div className="add-nft-form_item">
                                    <div className="add-nft-form_item__title">
                                        Set a price
                                    </div>
                                    <div className="set-price-group">
                                        <input type="text" className="set-price-input" placeholder="Amount" disabled/>
                                        <Select
                                            disabled
                                            className="set-price-input-select"
                                            defaultValue="BNB"
                                        />
                                    </div>
                                </div>
                                <div className="add-nft-form_item">
                                    <div className="add-nft-form_item__title">
                                        Period
                                    </div>
                                    <Select
                                        disabled
                                        className="period-selector"
                                        defaultValue="7 days"
                                    />
                                </div>
                                <div className="add-nft-form_item">
                                    <div className="add-nft-form_item__title">
                                        Category
                                    </div>
                                    <Select
                                        disabled
                                        className="period-selector"
                                        defaultValue="All"
                                    />
                                </div>
                                <div className="token-info">
                                    <div className="add-nft-form_item w-100">
                                        <div className="add-nft-form_item__title">
                                            Token address
                                        </div>
                                        <input type="text" className="token-info-input" disabled/>
                                    </div>
                                    <div className="add-nft-form_item w-100">
                                        <div className="add-nft-form_item__title">
                                            Token ID
                                        </div>
                                        <input type="text" className="token-info-input" disabled/>
                                    </div>
                                </div>
                                <div className="add-nft-form_item">
                                    <div className="add-nft-form_item__title">
                                        NFT description
                                    </div>
                                    <textarea className="nft-description" placeholder="Add a few words about NFT:" disabled/>
                                </div>
                            </div>

                        </div>

                        <div className="sale-right-side">
                            <div className="sales-nft-card">
                                <div className="sales-nft-card_nftimage" >
                                    <img src={NFT} alt=""/>
                                </div>
                                <div className="sales-nft-card_footer">
                                    <div className="sales-nft-card_footer_top">
                                        <span className="sales-nft-card_footer_top_price1">xxx</span>
                                        <span className="sales-nft-card_footer_top_price2">xxx</span>
                                    </div>
                                    <div className="sales-nft-card_footer_bottom">
                                        {"0000".slice(0,1) + "." + "0000".slice(1)} BNB
                                    </div>
                                </div>
                            </div>
                            <button className="accept-offer not-hover" disabled style={{cursor: "auto"}}>
                                Accept offer {"0000".slice(0,1) + "." + "0000".slice(1)} BNB
                            </button>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
            <div className="info_item">
                <div className="info_item_title">
                    3. Header
                </div>
                <div className="info_item_content">
                    <div className="info_item_content_first-block">
                        <span>
                            RED COST is the official marketplace for valuing, buying and selling digital assets (NFT). Complies with all current cryptocurrency security requirements. All transactions are legal and transparent. To create a safe and convenient platform for everyone who understands the topic and wants to earn safely on NFT.
                        </span>
                    </div>
                    <div className="info_item_content_second-block">
                        <div className="add-nft-form_item">
                            <div className="add-nft-form_item__title">
                                Set a price
                            </div>
                            <div className="set-price-group">
                                <input type="text" className="set-price-input" placeholder="Amount" disabled/>
                                <Select
                                    disabled
                                    className="set-price-input-select"
                                    defaultValue="BNB"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="info_item">
                <div className="info_item_title">
                    4. Header
                </div>
                <div className="info_item_content">
                    <div className="info_item_content_first-block">
                        <span>
                            RED COST is the official marketplace for valuing, buying and selling digital assets (NFT). Complies with all current cryptocurrency security requirements. All transactions are legal and transparent. To create a safe and convenient platform for everyone who understands the topic and wants to earn safely on NFT.
                        </span>
                    </div>
                    <div className="info_item_content_second-block">
                        <div className="add-nft-form_item">
                            <div className="add-nft-form_item__title">
                                Set a price
                            </div>
                            <div className="set-price-group">
                                <input type="text" className="set-price-input" placeholder="Amount" disabled />
                                <Select
                                    disabled
                                    className="set-price-input-select"
                                    defaultValue="BNB"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
};

export default NFTInfo;